import React from "react";
import {useDispatch,useSelector} from 'react-redux';
import {setCurrentStep} from '../../../../redux/actions/ui-actions';
import {setVideoID} from '../../../../redux/actions/video-actions';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { text } from "../../../../services/LanguageService";
import {getURL} from '../../../../services/BackendService';
import {toast} from 'react-toastify';
import axios from "axios";
import "./VideoForm.scss";
import { setLoginDialog } from "../../../../redux/actions/login-actions";

// const BootstrapInput = styled(InputBase)(({ theme }) => ({
// 	'label + &': {
// 	  marginTop: theme.spacing(1),
//       color: "white"
// 	},
// 	'& .MuiInputBase-input': {
// 		borderRadius: 5,
// 		fontSize: 16,          
// 		padding: '3px 20px 11px 20px',
// 		border: '1px solid #585858',
// 		background: '#171825',
// 		'&:focus': {
// 			borderRadius: 4,
// 			border: '1px solid #585858',
// 			color:'#FFFFFF',
// 			backgroundColor: '#1b1c26',
// 		},
// 	},
// }));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(1),
    },
    '& .MuiInputBase-input': {
      borderRadius: 5,
      backgroundColor: '#1b1c26',
      color:'#FFFFFF',
      border: '2px solid #D2C8ED',
      fontSize: 16,          
      padding: '5px 26px 5px 12px',
      '&:focus': {
        borderRadius: 5,
        borderColor: '#80bdff',
        color:'#FFFFFF',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
}));

export default function VideoForm() {

	const dispatch = useDispatch();
    const {logged} = useSelector(state => state.loginReducer);
    const [example, setExample] = React.useState('');
    const [url, setUrl] = React.useState("");
	const exampleList = [
        "https://www.youtube.com/watch?v=rJGBLjMnN80",
        "https://www.youtube.com/watch?v=q1r8gyv-Jns",
        "https://www.youtube.com/watch?v=cEkQFO3A3p4"
    ];

    React.useEffect(() => {
        setTimeout(() => {
            const url = new URL(window.location.href);
            if (url.searchParams.get('v')) {
                const video = url.searchParams.get('v');
                analyze(`https://www.youtube.com/watch?v=${video}`);
            }
        }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateExample = (event) => {
        setExample(event.target.value);
        setUrl(event.target.value);
    };

    async function analyze(alternativeURL) {
        const videoUrl = alternativeURL ? alternativeURL : url;
        const videoId = youtubeParser(videoUrl);

        if(!videoId) {
            toast.error(text("bad_url"));
            return;
        }

        const analyzed = await videoIsAnalyzed(videoId);
        if(!logged && !exampleList.includes(videoUrl) && !analyzed) {
            openLoginPanel();
            return;
        }

        if(!videoId) {
            toast.error(text("bad_url"));
            return;
        }

        dispatch(setVideoID(videoId));
        dispatch(setCurrentStep("AWAITING_BACKEND_RESPONSE"));
    }

    async function videoIsAnalyzed(id) {
        const url = getURL(`api/check/${id}`);
		const {data} = await axios.get(url);
		return data.analyzed;
    }

    function openLoginPanel() {
        dispatch(setLoginDialog("FORCED"));
    }

    function youtubeParser(url) {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }

    function handleKeyDown(event) {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault();
            analyze();
        }
    }

    return (
		<div className="video-form">
            <div className="examples-selector-container">
                <FormControl sx={{ m: 1, minWidth: 120}} >
                    <InputLabel id="autowidth-label" sx={{color:'#FFFFFF'}}>{text("example")}s</InputLabel>
                    <Select
                        labelId="autowidth-label"
                        id="select-example"
                        value={example}
                        onChange={updateExample}
                        autoWidth
                        label="Examples"
                        input={<BootstrapInput />}
                    >
                        <MenuItem value="">{text("example")}s</MenuItem>
                        {exampleList.map((value, index) => {
                            return <MenuItem key={index} value={value}>{text("example")} {index}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </div>
			<div className="input-group">
                <input 
                    type="url" 
                    className="form-control" 
                    placeholder={text("url")}
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <div className="input-group-append">
                    <button className="btn syntube-gradient" type="button" onClick={() => analyze()}>{text("analyze")}</button>
                </div>
            </div>
		</div>
    )

}