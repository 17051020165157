import React from "react";
import ReloadButton from "./Reload-Button/Reload-Button";
import MetadataContainer from "./Metadata-Container/Metadata-Container";
import "./Results-Page.scss";

export default function ResultsPage() {

    return (
		<div className="results-page">
			<div className="reload-button-container"><ReloadButton /></div>
			<div className="metadata-container"><MetadataContainer /></div>
		</div>
    )

}