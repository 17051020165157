const defaultState = {
    language: "ES",
    results_language: "ES",
    step: "AWAITING_USER_INPUT",
    queue: {
        position: 0,
        length: 0
    },
    analysis_progress: {

    },
    visibility: {
        hashtags: true,
        summary: true,
        timestamps: true
    }
}

const uiReducer = (state = defaultState, action) => {
	switch(action.type) {
    case 'SET_CURRENT_STEP':
        return {
            ...state,
            step: action.payload.step
        }
    case 'SET_QUEUE_STATUS':
        return {
            ...state,
            queue: {
                position: action.payload.position,
                length: action.payload.length
            }
        }
    case 'SET_ANALYSIS_PROGRESS':
        return {
            ...state,
            analysis_progress: action.payload.task
        }
    case 'SET_LANGUAGE':
        return {
            ...state,
            language: action.payload.language
        }
    case 'SET_RESULTS_LANGUAGE':
        return {
            ...state,
            results_language: action.payload.language
        }
    case 'TOGGLE_VISIBILITY':
        return {
            ...state,
            visibility: {
                ...state.visibility,
                [action.payload.target]: !state.visibility[action.payload.target]
            }
    }
    default:
        return state;
	}
}

export default uiReducer;