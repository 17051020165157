import React from "react";
import Chip from '@mui/material/Chip';
import {useSelector,useDispatch} from 'react-redux';
import {removeTag} from '../../../../../../../redux/actions/video-actions';
import {toast} from 'react-toastify';
import { text } from "../../../../../../../services/LanguageService";
import "./Tags.scss";

export default function Tags() {

	const dispatch = useDispatch();
    const resultsLanguage = useSelector(state => state.uiReducer.results_language);
    const {tags} = useSelector(state => state.videoReducer.metadata[getResultLanguage(resultsLanguage)]);

    function getResultLanguage() {
		return resultsLanguage === "GB" ? "en" : resultsLanguage.toLowerCase();
	}
    
	function handleDeletion(tag) {
		const lang = getResultLanguage();
		dispatch(removeTag(lang, tag));
	}

	function handleCopy() {
		const str = tags.toString().replaceAll(",", ", ");
		copyToClipboard(str);
		
		function copyToClipboard(content) {
			const el = document.createElement('textarea');
			el.value = content;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			toast.info(text("copied"));
		}
	}

    return (
		<div className="tags">
			<div className="list">
				{tags.map(tag => {
					return <Chip label={tag} key={tag} onDelete={() => handleDeletion(tag)} />
				})}
			</div>
			<div className="copy-button-container">
				<button onClick={handleCopy}>{text("copy")}</button>
			</div>
		</div>
    )

}