const defaultState = {
    id: "",
    metadata: {

    }
}

const videoReducer = (state = defaultState, action) => {
	switch(action.type) {
    case 'SET_VIDEO_ID':
        return {
            ...state,
            id: action.payload.id
        }
    case 'SET_METADATA':
        return {
            ...state,
            metadata: action.payload.metadata
        }
    case 'REMOVE_TAG': {
        return {
            ...state,
            metadata: {
                ...state.metadata,
                [action.payload.language]: {
                    ...state.metadata[action.payload.language],
                    tags: state.metadata[action.payload.language].tags.filter(x => x !== action.payload.tag)
                }
            }
        }
    }
    default:
        return state;
	}
}

export default videoReducer;