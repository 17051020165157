import React from "react";
import {useSelector,useDispatch} from 'react-redux';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import {loginUser, setLoginDialog} from "../../../../redux/actions/login-actions";
import {GoogleLogin} from 'react-google-login';
import "./Login-Dialog.scss";
import { text } from "../../../../services/LanguageService";
import LegalDialog from "../../../shared/Legal-Dialog/Legal-Dialog";
import ConditionsDialog from "../../../shared/Conditions-Dialog/Conditions-Dialog";

export default function LoginDialog() {

	const dispatch = useDispatch();
    const dialog = useSelector(state => state.loginReducer.dialog);

	const [privateOpen, setPrivacyOpen] = React.useState(false);
	const [conditionsOpen, setConditionsOpen] = React.useState(false);

	const responseGoogle = (response) => {
		const {tokenObj, profileObj} = response;
		localStorage.setItem("login", JSON.stringify({tokenObj, profileObj}));
		dispatch(loginUser(tokenObj, profileObj));
		handleCloseDialog();
	}

	function handleCloseDialog() {
		dispatch(setLoginDialog("CLOSED"));
	}

	function getDialogTitle() {
		switch(dialog) {
		case "CLOSED":
			return "";
		case "BUTTON":
			return text("login_button");
		case "FORCED":
			return text("login_forced");
		default:
			break;
		}
	}

    return (
		<Dialog className="login-dialog" onClose={handleCloseDialog} open={dialog !== "CLOSED"}>
			<DialogContent>
				<div className="upper-text">{getDialogTitle()}</div>
				<div className="google-button">
					<GoogleLogin
						clientId="482406446405-vg2ujf89ag8fjg4gd2l4sfcso8i9c1kg.apps.googleusercontent.com"
						buttonText="Sign in with Google"
						onSuccess={responseGoogle}
						onFailure={() => {}}
						cookiePolicy={'single_host_origin'}
					/>
				</div>
				<div className="privacy">
					Al entrar aceptas la 
					<span onClick={() => setPrivacyOpen(true)}> política de privacidad </span> 
					y 
					<span onClick={() => setConditionsOpen(true)}> condiciones de uso </span> 
					de SynTube.
				</div>
			</DialogContent>
			<LegalDialog onClose={() => setPrivacyOpen(false)} open={privateOpen} />
			<ConditionsDialog onClose={() => setConditionsOpen(false)} open={conditionsOpen} />
		</Dialog>
    )

}