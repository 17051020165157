import React from "react";
import "./Footer.scss";

export default function Footer() {

    return (
		<footer>
			<a href="https://www.ugiat.com/">Copyright 2023: A product by Ugiat Technologies</a>
		</footer>
    )

}