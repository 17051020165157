export const loginUser = (token, profile) => {
    return {
        type: 'LOGIN_USER',
        payload: {
            token,
            profile
        }
    };
};

export const logoutUser = () => {
    return {
        type: 'LOGOUT_USER'
    };
};

export const setLoginDialog = (dialog) => {
    return {
        type: 'SET_LOGIN_DIALOG',
        payload: {
            dialog
        }
    };
};