import React from "react";
import {useSelector} from 'react-redux';
import "./Video.scss";

export default function Video() {

    const id = useSelector(state => state.videoReducer.id);

	React.useEffect(() => {
        let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        let player;

        window.onYouTubeIframeAPIReady = function () {
            player = new window.YT.Player('player', {
                videoId: id
            });
        }

        window.setCurrentTime = function (slideNum) {
            player.seekTo(slideNum);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    return (
		<div className="video">
			<div id="player"></div>
		</div>
    )

}