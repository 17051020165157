import React from "react";
import {useDispatch} from 'react-redux';
import Logo from "./Logo/Logo";
import LoginButton from "./Login-Button/Login-Button";
import Hamburguer from "./Hamburguer/Hamburguer";
import LanguageSelector from "../shared/Language-Selector/Language-Selector";
import {setLanguage} from '../../redux/actions/ui-actions';
import "./Header.scss";

export default function Header() {

	const dispatch = useDispatch();

	function updateWebLanguage(lang) {
		dispatch(setLanguage(lang));
	}

    return (
		<header>
			<div className="logo-container">
				<Logo />
			</div>
			<div className="options-container">
				<div className="login-button-container">
					<LoginButton />
				</div>
				<div className="language-container">
					<LanguageSelector 
						onLanguageChange={updateWebLanguage} 
						svgPath="world.svg"
					/>
				</div>
				<div className="hamburguer-container">
					<Hamburguer />
				</div>
			</div>
		</header>
    )

}