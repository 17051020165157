import React from "react";
import {useSelector,useDispatch} from 'react-redux';
import SwitchButton from "./SwitchButton/SwitchButton";
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {toggleVisibility} from '../../../../redux/actions/ui-actions';
import {logoutUser} from '../../../../redux/actions/login-actions';
import {text} from '../../../../services/LanguageService';
import "./Menu.scss";
import LegalDialog from "../../../shared/Legal-Dialog/Legal-Dialog";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
// 	'& .MuiDialogContent-root': {
// 	  padding: theme.spacing(2),
// 	},
// 	'& .MuiDialogActions-root': {
// 	  padding: theme.spacing(1),
// 	},
// }));
  
// const BootstrapDialogTitle = (props) => {
// 	const { children, onClose, ...other } = props;
  
// 	return (
// 	  <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
// 		{children}
// 		{onClose ? (
// 		  <IconButton
// 			aria-label="close"
// 			onClick={onClose}
// 			sx={{
// 			  position: 'absolute',
// 			  right: 8,
// 			  top: 8,
// 			  color: (theme) => theme.palette.grey[500],
// 			}}
// 		  >
// 			<CloseIcon />
// 		  </IconButton>
// 		) : null}
// 	  </DialogTitle>
// 	);
// };
  
// BootstrapDialogTitle.propTypes = {
// 	children: PropTypes.node,
// 	onClose: PropTypes.func.isRequired,
// };

export default function Menu() {

	const dispatch = useDispatch();
    const visibilityData = useSelector(state => state.uiReducer.visibility);
    const logged = useSelector(state => state.loginReducer.logged);
	const [open, setOpen] = React.useState(false);

	function isVisible(target) {
		return visibilityData[target];
	}

	function handleVisibilityToggle(target) {
		dispatch(toggleVisibility(target));
	}

	function redirect(url) {
		window.location.href = url; 
	}

	const handleClickOpen = () => {
		setOpen(true);
	}

	const handleClose = () => {
		setOpen(false);
	}

	function handleLogin() {
        const button = document.querySelector(".google-login > button");
        button.click();
	}

	function handleLogout() {
		localStorage.removeItem("login");
		dispatch(logoutUser());
	}

    return (
		<div className="hamburguer-menu">
			<div className="wrapper">
				{logged ? (
					<div className="menu-login-phone">
						<div className="left">{text("logout")}</div>
						<div className="right"><button onClick={handleLogout}>{text("logout")}</button></div>
					</div>
				) : (
					<div className="menu-login-phone">
						<div className="left">{text("login")}</div>
						<div className="right"><button onClick={handleLogin}>{text("login")}</button></div>
					</div>
				)}
				<div>
					<div className="left">{text("hashtags")}</div>
					<div className="right"><SwitchButton active={isVisible("hashtags")} onClick={() => handleVisibilityToggle("hashtags")} /></div>
				</div>
				<div>
					<div className="left">{text("summary")}</div>
					<div className="right"><SwitchButton active={isVisible("summary")} onClick={() => handleVisibilityToggle("summary")} /></div>
				</div>
				<div>
					<div className="left">{text("timestamps")}</div>
					<div className="right"><SwitchButton active={isVisible("timestamps")} onClick={() => handleVisibilityToggle("timestamps")} /></div>
				</div>
				<div>
					<div className="left">{text("aboutus")}</div>
					<div className="right"><button onClick={() => redirect('https://www.ugiat.com/')}>{text("viewapp")}</button></div>
				</div>
				<div>
					<div className="left">{text("uplayer")}</div>
					<div className="right"><button onClick={() => redirect('https://www.uplayer.ugiat.com/')}>{text("viewapp")}</button></div>
				</div>
				<div>
					<div className="left">{text("privacy")}</div>
					<div className="right"><button onClick={handleClickOpen}>{text("read")}</button></div>
				</div>
			</div>
			<LegalDialog onClose={handleClose} open={open} />
		</div>
    )

}