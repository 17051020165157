import React from "react";
import {useSelector} from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { text } from "../../../../services/LanguageService";
import config from '../../../../config/config';
import {toast} from 'react-toastify';
import axios from "axios";
import "./Mail-Confirmation.scss";

export default function MailConfirmation() {
    
	const [checked, setChecked] = React.useState(false);
    const id = useSelector(state => state.videoReducer.id);
    const mail = useSelector(state => state.loginReducer.profile.email);

	function handleCheck() {
		if(!checked) {
			setChecked(true);
			const mailUrl = getURL(`api/mailing/${mail}/${id}`);
			axios.get(mailUrl);
			toast.info(text("mail"));    
		}
	}

	function getURL(endpoint) {
        //const location = window.location;
        const backend = /*location.hostname === "localhost" ? config.dev_backend :*/ config.production_backend;
        return backend + endpoint;
    }

    return (
		<div className="mail-confirmation">
			<FormGroup>
				<FormControlLabel control={<Checkbox checked={checked} disabled={checked} onClick={handleCheck} />} label={text("send_email")} />
			</FormGroup>
		</div>
    )

}