import React from "react";
import {useSelector} from 'react-redux';
import StartPage from "./Start-Page/Start-Page";
import ResultsPage from "./Results-Page/Results-Page";
import "./Body.scss";

export default function Body() {

	const step = useSelector(state => state.uiReducer.step);

    return (
		<div className="body">
			{step !== "SHOWING_RESULTS" ? (
				<div className="start-page-container"><StartPage /></div>
			) : (
				<div className="reuslts-page-container"><ResultsPage /></div>
			)}
		</div>
    )

}