export const setCurrentStep = (step) => {
    return {
        type: 'SET_CURRENT_STEP',
        payload: {
            step
        }
    };
};

export const setQueueStatus = (position, length) => {
    return {
        type: 'SET_QUEUE_STATUS',
        payload: {
            position,
            length
        }
    };
};

export const setAnalysisProgress = (task) => {
    return {
        type: 'SET_ANALYSIS_PROGRESS',
        payload: {
            task
        }
    };
};

export const setLanguage = (language) => {
    return {
        type: 'SET_LANGUAGE',
        payload: {
            language
        }
    };
};

export const setResultsLanguage = (language) => {
    return {
        type: 'SET_RESULTS_LANGUAGE',
        payload: {
            language
        }
    };
};

export const toggleVisibility = (target) => {
    return {
        type: 'TOGGLE_VISIBILITY',
        payload: {
            target
        }
    };
};