export const setVideoID = (id) => {
    return {
        type: 'SET_VIDEO_ID',
        payload: {
            id
        }
    };
};

export const setMetadata = (metadata) => {

    if(Object.keys(metadata).length === 0) {
        metadata = {
            es: {
                hashtags: [],
                subtitles: "",
                tags: [],
                timestamps: [],
                video_summary: ""
            },
            en: {
                hashtags: [],
                subtitles: "",
                tags: [],
                timestamps: [],
                video_summary: ""
            }
        }
    }

    return {
        type: 'SET_METADATA',
        payload: {
            metadata
        }
    };
};

export const removeTag = (language, tag) => {
    return {
        type: 'REMOVE_TAG',
        payload: {
            language, 
            tag
        }
    };
};