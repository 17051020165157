import React from "react";
import {text} from '../../../../services/LanguageService';
import "./Reload-Button.scss";

export default function ReloadButton() {

	function handleReload() {
		window.location.replace(window.location.origin);
	}

    return (
		<div className="reload-button">
			<button className="syntube-gradient" onClick={handleReload}>{text("restart")}</button>
		</div>
    )

}