import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Subtitles from "./Subtitles/Subtitles";
import DescriptionTags from "./Description-Tags/Description-Tags";
import {text} from '../../../../../services/LanguageService';
import "./Data-Display.scss";

export default function DataDisplay() {

    const [panel, setPanel] = React.useState(0);

    function handlePanelChange(_, val) {
        setPanel(val);
    }

    return (
		<div className="data-display">
            <div className="tabs">
                <Tabs value={panel} onChange={handlePanelChange}>
                    <Tab label={text("description_and_tags")} />
                    <Tab label={text("subtitles")} />
                </Tabs>
            </div>
            {panel === 0 ? (
                <div className="description-container">
                    <DescriptionTags />
                </div>
            ) : (
                <div className="subtitles-container">
                    <Subtitles />
                </div>
            )}
		</div>
    )

}
