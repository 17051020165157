import React from "react";
import {useSelector} from 'react-redux';
import {text} from "../../../../services/LanguageService";
import "./Title.scss";

export default function Title() {

    const {step, queue, analysis_progress} = useSelector(state => state.uiReducer);
	const title = getCurrentTitle();

	function translateBackendResponse(response) {
		switch(response) {
			case "subtitles":
				return text("backend_subtitles");
			case "segmentation":
				return text("backend_segmentation");
			case "summary":
				return text("backend_summary");
			case "chapters":
				return text("backend_chapters");
			case "translate":
				return text("backend_translate");
			case "finished":
				return text("backend_finished");
			default:
				return 'Processing/Procesando...'
		}
	}

	function getCurrentTitle() {
		if(step === "AWAITING_USER_INPUT" || step === "AWAITING_BACKEND_RESPONSE") {
			return text("title");
		}
		
		else if(step === "AWAITING_IN_QUEUE") {
			console.log(queue)
			return text("queueposition").replace("%a", queue.position).replace("%b", queue.length);
		}

		else if("AWAITING_BACKEND_ANALYSIS") {
			return translateBackendResponse(analysis_progress)
		}

		return "";
	}

    return (
		<div className="title">{title}</div>
    )

}