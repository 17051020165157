const config = {
    dev_backend: "http://83.61.6.12:7000/",
    production_backend: "https://syntube.ugiat.com/",
    msg: {
        ES: {
            title: "¡Gana visualizaciones con Syntube!",
            description: "Syntube analiza el contenido para generar una mejor descripción y enriquecer la experiencia de usuario.",
            example: "Ejemplo",
            send_email: "Notificarme por email cuando se complete.",
            mail: "Te notificaremos cuando se complete.",
            url: "URL del video de youtube",
            analyze: "Analizar",
            login: "Login",
            logout: "Salir",
            hashtags: "Hashtags",
            tags: "Etiquetas",
            summary: "Resumen",
            timestamps: "Timestamps",
            aboutus: "Sobre nosotros",
            uplayer: "uPlayer",
            privacy: "Privacidad",
            read: "Leer",
            viewapp: "Ir a la app",
            restart: "Analizar otro video",
            description_and_tags: "DESCRIPCIÓN Y TAGS",
            subtitles: "SUBTÍTULOS MEJORADOS",
            download_subtitles: "Descargar subtítulos",
            copy: "Copiar",
            bad_url: "La dirección de youtube no es válida.",
            copied: "Se ha copiado el resultado.",
            welcome: "Bienvenido",
            queueposition: "Esperando en la cola, tu posición es la %a de %b",
            login_button: "Bienvenido a SynTube",
            login_forced: "Bienvenido a Syntube, selecciona una cuenta de google para analizar un video nuevo.",
            backend_subtitles: "Video sin subtítulos, generándolos...",
            backend_segmentation: "Aplicando segmentación...",
            backend_summary: "Generando resumen...",
            backend_chapters: "Generando titulares...",
            backend_translate: "Traduciendo resultados al inglés...",
            backend_finished: "Análisis terminado",
            no_speech_error_1: "El audio del video analizado no contiene ningún speech.",
            no_speech_error_2: "Analiza videos que tengan hablantes para poder obtener los resultados."
        },
        GB: {
            title: "Increase visualisations with Syntube!",
            description: "Syntube analyses the content to generate a better description and improve the user experience.",
            example: "Example",
            send_email: "Send me an email when it's ready.",
            mail: "We'll send you an email when it's ready.",
            url: "Youtube video URL",
            analyze: "Analyze",
            login: "Login",
            logout: "Logout",
            tags: "Tags",
            hashtags: "Hashtags",
            summary: "Summary",
            timestamps: "Timestamps",
            aboutus: "About us",
            uplayer: "uPlayer",
            privacy: "Privacy",
            read: "Read",
            viewapp: "View app",
            restart: "Analyze another video",
            description_and_tags: "DESCRIPTION AND TAGS",
            subtitles: "IMPROVED SUBTITLES",
            download_subtitles: "Download subtitles",
            copy: "Copy",
            bad_url: "The youtube address is not valid.",
            copied: "The result has been copied.",
            welcome: "Welcome",
            queueposition: "Waiting in the queue, your position is %a of  %b",
            login_button: "Welcome to SynTube",
            login_forced: "Welcome to Syntube, select a google account to analyse a new video.",            
            backend_subtitles: "Generating subtitles...",
            backend_segmentation: "Applying segmentation...",
            backend_summary: "Generating summaries...",
            backend_chapters: "Generating titles...",
            backend_translate: "Translating to English...",
            backend_finished: "Analysis finished",
            no_speech_error_1: "The audio of the analyzed video does not contain any speech.",
            no_speech_error_2: "Analyze videos that contains speech in order to get the results."
        }
    }
}

export default config;