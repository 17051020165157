import React from "react";
import {useSelector,useDispatch} from 'react-redux';
import Title from "./Title/Title";
import Description from "./Description/Description";
import VideoForm from "./VideoForm/VideoForm";
import Spinner from "./Spinner/Spinner";
import MailConfirmation from "./MailConfirmation/Mail-Confirmation";
import {setAnalysisProgress, setCurrentStep,setQueueStatus} from '../../../redux/actions/ui-actions';
import {setMetadata} from '../../../redux/actions/video-actions';
import config from '../../../config/config';
import {toast} from 'react-toastify';
import axios from "axios";
import "./Start-Page.scss";
import { text } from "../../../services/LanguageService";
import NoDescription from "./NoDescription/NoDescription";

export default function StartPage() {

	const dispatch = useDispatch();
    const step = useSelector(state => state.uiReducer.step);
    const id = useSelector(state => state.videoReducer.id);
	const [speech, setSpeech] = React.useState(true);

	React.useEffect(() => {
		if(step === "AWAITING_BACKEND_RESPONSE") {
			const interval = setInterval(async () => {  
				setSpeech(true);
				queryRestAPI(interval);
			}, 5000);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [step]);

	async function queryRestAPI(interval) {
		const restApiUrl = getURL(`api/video/${id}`);
		const {data: fetchData} = await axios.get(restApiUrl);
		const {status, data} = fetchData;
		
		if(status === "queue") {
			dispatch(setCurrentStep("AWAITING_IN_QUEUE"));
			dispatch(setQueueStatus(data.position, data.queue_length));
		}

		else if(status === "failed") {
			dispatch(setCurrentStep("AWAITING_USER_INPUT"));
			clearInterval(interval);
		}

		else if(status === "processing") {
			dispatch(setCurrentStep("AWAITING_BACKEND_ANALYSIS"));
			dispatch(setAnalysisProgress(data.task));
		}

		else if(status === "ready" && Object.keys(data.description).length === 0) {
			dispatch(setCurrentStep("AWAITING_USER_INPUT"));
			setSpeech(false);
			clearInterval(interval);
		}
		
		else if(status === "ready") {
			dispatch(setMetadata(data.description));
			dispatch(setCurrentStep("SHOWING_RESULTS"));
			clearInterval(interval);
		}
		
	}

	function getURL(endpoint) {
        //const location = window.location;
        const backend = /*location.hostname === "localhost" ? config.dev_backend :*/ config.production_backend;
        return backend + endpoint;
    }

    return (
		<div className="start-page">
			
			<div className="title-container"><Title /></div>
			<div className="description-container"><Description /></div>

			{step === "AWAITING_BACKEND_RESPONSE" && (
				<div className="spinner-container"><Spinner /></div>
			)}
			
			{step === "AWAITING_USER_INPUT" && (
				<div className="video-form-container">
					<VideoForm />
					{!speech && (
						<div className="speech-container"><NoDescription /></div>
					)}
				</div>
			)}

			{(step === "AWAITING_BACKEND_ANALYSIS" || step === "AWAITING_IN_QUEUE") && (
				<React.Fragment>
					<div className="email-form-container"><MailConfirmation /></div>
				</React.Fragment>
			)}

		</div>
    )

}