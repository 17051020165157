const defaultState = {
    token: null,
    profile: null,
    logged: false,
    dialog: "CLOSED"
}

const loginReducer = (state = defaultState, action) => {
	switch(action.type) {
    case 'LOGIN_USER':
        return {
            ...state,
            token: action.payload.token,
            profile: action.payload.profile,
            logged: true
        }
    case 'LOGOUT_USER':
        return {
            ...state,
            logged: false
        }
    case 'SET_LOGIN_DIALOG':
        return {
            ...state,
            dialog: action.payload.dialog
        }
    default:
        return state;
	}
}

export default loginReducer;