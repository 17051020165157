import React from 'react';
import Header from './components/Header/Header';
import Body from './components/Body/Body';
import Footer from './components/Footer/Footer';
import {ToastContainer} from 'react-toastify';
import {useSelector} from 'react-redux';
import ReactGa from 'react-ga';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

function App() {

	useSelector(state => state.uiReducer);

	React.useEffect(() => {
		ReactGa.initialize("UA-221167942-1", {
			debug: true
		});
		ReactGa.pageview("/");
	}, []);

	return (
		<div className='App'>
			<div className='header-container'><Header /></div>
			<div className='body-container'><Body /></div>
			<div className='footer-container'><Footer /></div>
			<div className='toast-container'><ToastContainer /></div>
		</div>
	);

}

export default App;
