import config from "../config/config";

function getURL(endpoint) {
    //const location = window.location;
    const backend = /*location.hostname === "localhost" ? config.dev_backend :*/ config.production_backend;
    return backend + endpoint;
}

export {
    getURL
};
