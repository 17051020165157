import React from "react";
import {text} from "../../../../services/LanguageService";
import "./NoDescription.scss";

export default function NoDescription() {

    return (
		<div className="no-description">
			<div>{text("no_speech_error_1")}</div>
			<div>{text("no_speech_error_2")}</div>
		</div>
    )

}