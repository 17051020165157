import React from "react";
import Video from "./Video/Video";
import DataDisplay from "./Data-Display/Data-Display";
import "./Metadata-Container.scss";

export default function MetadataContainer() {

    return (
		<div className="metadata-container">
			<div className="video-container"><Video /></div>
			<div className="data-display-container"><DataDisplay /></div>
		</div>
    )

}