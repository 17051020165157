import React from "react";
import {useSelector,useDispatch} from 'react-redux';
import {setResultsLanguage,toggleVisibility} from '../../../../../../../redux/actions/ui-actions';
import {text} from '../../../../../../../services/LanguageService';
import {toast} from 'react-toastify';
import "./Descriptions.scss";
import LanguageSelector from "../../../../../../shared/Language-Selector/Language-Selector";

export default function Descriptions() {
    
	const dispatch = useDispatch();
    const resultsLanguage = useSelector(state => state.uiReducer.results_language);
    const visibilityData = useSelector(state => state.uiReducer.visibility);
    const {video_summary, timestamps, hashtags, subtitles} = useSelector(state => state.videoReducer.metadata[getResultLanguage(resultsLanguage)]);

	React.useEffect(() => {
		registerObserver();

		if(subtitles === "") {
			showNoDescriptionError();
		}

		async function registerObserver() {
			while(!document.querySelector(".video-container .video iframe")) {
				await new Promise(r => setTimeout(r, 200));
			}

			const container = document.querySelector(".video-container .video iframe");
			const resizeObserver = new ResizeObserver(function(entries) {
				let rect = entries[0].contentRect;
				let height = rect.height;
	
				let target = document.querySelector(".description-data-container .descriptions");
				target.setAttribute("style","height:" + (height - 156) + "px");
			});
			
			resizeObserver.observe(container);
		}
	}, []);

	function getResultLanguage(resultsLanguage) {
		return resultsLanguage === "GB" ? "en" : resultsLanguage.toLowerCase();
	}

	function handleLanguageChange(code) {
		dispatch(setResultsLanguage(code));
	}

	function getTimestamps() {
        return (
			timestamps.map((element) => {
				const {minute, summary, seconds} = element;
				return (
					<div key={seconds}><span onClick={() => window.setCurrentTime(seconds)}>{minute} </span>{summary}</div>
				)
			})
        )
    }

	function isVisible(target) {
		return visibilityData[target];
	}

	function visibleTag(target) {
		return isVisible(target) ? "" : "disabled";
	}

	function handleVisibilityToggle(target) {
		dispatch(toggleVisibility(target));
	}

	function copy() {
		const element = document.querySelector(".metadata-final-display");
        const str = element.innerText.replace(/(\n\n)/gm, '\n');
        copyToClipboard(str);

		function copyToClipboard(content) {
			const el = document.createElement('textarea');
			el.value = content;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			toast.info(text("copied"));
		}
	}

	function showNoDescriptionError() {
		toast.error(text("no_speech_error_1"));
		toast.error(text("no_speech_error_2"));
	}

    return (
		<div className="descriptions">
			<div className="visibility-buttons">
				<div className="language-button-container">
					<LanguageSelector
						id="language-selector"
						onLanguageChange={handleLanguageChange} 
						svgPath="translate.svg"
					/>
				</div>
				<div className={"checkbox-button " + visibleTag("summary")} onClick={() => handleVisibilityToggle("summary")}><div></div>{text("summary")}</div>
				<div className={"checkbox-button " + visibleTag("timestamps")} onClick={() => handleVisibilityToggle("timestamps")}><div></div>{text("timestamps")}</div>
				<div className={"checkbox-button " + visibleTag("hashtags")} onClick={() => handleVisibilityToggle("hashtags")}><div></div>{text("hashtags")}</div>
				<div className="full-space"></div>
				<div className="copy-button-container" onClick={copy}><button>{text("copy")}</button></div>
			</div>
			<div className="metadata-final-display">
				{isVisible("summary") && (<div className="summary">{video_summary}</div>)}
				{isVisible("timestamps") && (<div className="timestamps">{getTimestamps()}</div>)}
				{isVisible("hashtags") && (<div className="hashtags">{hashtags.toString().replaceAll(",", ", ")}</div>)}
			</div>
		</div>
    )

}
