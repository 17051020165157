import React from "react";
import Tags from "./Tags/Tags";
import "./Description-Tags.scss";
import Descriptions from "./Descriptions/Descriptions";

export default function DescriptionTags() {
    
    return (
		<div className="description-tags">
            <div className="description-data-container"><Descriptions /></div>
            <div className="tags-container"><Tags /></div>
		</div>
    )

}