import React from "react";
import "./Language-Selector.scss";

export default function LanguageSelector(props) {

	const {onLanguageChange, svgPath, id} = props;
	const [open, setOpened] = React.useState(false);

	function toggleOpen() {
		setOpened(!open);
	}

    return (
		<div className="language-selector-component" onClick={toggleOpen} id={id}>
			<div className="main">
				<img src={svgPath} alt="icon" />
			</div>
			<div className={open ? "options open" : "options"}>
				<div onClick={() => onLanguageChange("GB")}>
					{/* <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg"><g fill="none"><path d="M503.172 335.725H8.828A8.829 8.829 0 010 326.897V9.104A8.829 8.829 0 018.828.276h494.345a8.829 8.829 0 018.828 8.828v317.793a8.83 8.83 0 01-8.829 8.828z" fill="#41479B"></path><path d="M512 9.104a8.829 8.829 0 00-8.828-8.828h-39.495l-163.54 107.147V.276h-88.276v107.147L48.322.276H8.828A8.829 8.829 0 000 9.104v22.831l140.309 91.927H0v88.276h140.309L0 304.066v22.831a8.829 8.829 0 008.828 8.828h39.495l163.54-107.147v107.147h88.276V228.578l163.54 107.147h39.495a8.829 8.829 0 008.828-8.828v-22.831l-140.309-91.927H512v-88.276H371.691L512 31.935V9.104z" fill="#F5F5F5"></path><g fill="#FF4B55"><path d="M512 141.518H282.483V.276h-52.966v141.242H0v52.965h229.517v141.242h52.966V194.483H512z"></path><path d="M178.948 212.138L.25 328.135c.625 4.263 4.14 7.59 8.577 7.59h12.159l190.39-123.587-32.428.001v-.001zm167.44 0H313.96l190.113 123.404c4.431-.472 7.928-4.09 7.928-8.646v-7.258l-165.613-107.5zM0 18.849l161.779 105.014h32.428L5.143 1.137C2.123 2.54 0 5.555 0 9.104v9.745zm332.566 105.014L511.693 7.586c-.744-4.122-4.184-7.309-8.521-7.309h-12.647L300.138 123.863h32.428z"></path></g></g></svg> */}
					ENGLISH
				</div>
				<div onClick={() => onLanguageChange("ES")}>
					{/* <svg width="1em" height="1em" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg">
						<g fill="none">
						<path d="M8.828 335.724h494.345a8.829 8.829 0 008.828-8.828V9.103a8.829 8.829 0 00-8.828-8.828H8.828A8.83 8.83 0 000 9.103v317.793a8.828 8.828 0 008.828 8.828z" fill="#C8414B"></path>
						<path fill="#FFD250" d="M0 70.897h512v194.207H0z"></path>
						<path d="M216.276 168l7.485-33.681c.69-3.102-1.671-6.044-4.849-6.044h-5.272c-3.177 0-5.537 2.942-4.849 6.044L216.276 168z" fill="#C8414B"></path>
						<path fill="#F5F5F5" d="M207.448 150.345h17.655v75.034h-17.655z"></path><path fill="#FAB446" d="M203.034 141.517h26.483v8.828h-26.483z"></path>
						<path d="M185.379 168h44.138v8.828h-44.138zm44.138 35.31l-26.483-8.827v-8.828l26.483 8.828zM83.862 168l7.485-33.681c.69-3.102-1.671-6.044-4.849-6.044h-5.272c-3.177 0-5.537 2.942-4.849 6.044L83.862 168z" fill="#C8414B"></path>
						<path d="M114.759 141.517a8.829 8.829 0 00-8.828 8.828v57.379c0 10.725 10.01 30.897 44.138 30.897s44.138-20.171 44.138-30.897v-57.379a8.829 8.829 0 00-8.828-8.828h-70.62z" fill="#F5F5F5"></path>
						<path d="M150.069 185.655h-44.138v-35.31a8.829 8.829 0 018.828-8.828h35.31v44.138zm0 0h44.138v22.069c0 12.189-9.88 22.069-22.069 22.069-12.189 0-22.069-9.88-22.069-22.069v-22.069z" fill="#C8414B"></path>
						<path d="M105.931 185.655h44.138v22.069c0 12.189-9.88 22.069-22.069 22.069-12.189 0-22.069-9.88-22.069-22.069v-22.069z" fill="#FAB446"></path>
						<path d="M141.241 225.28v-39.625h-8.828v43.693c3.284-.666 6.251-2.12 8.828-4.068zm-17.655 4.068v-43.693h-8.828v39.625c2.578 1.948 5.545 3.402 8.828 4.068z" fill="#C8414B"></path>
						<path fill="#FFB441" d="M114.759 168h26.483v8.828h-26.483z"></path><g fill="#FAB446"><path d="M114.759 150.345h26.483v8.828h-26.483z"></path>
						<path d="M119.172 155.586h17.655v15.992h-17.655z"></path></g><path fill="#F5F5F5" d="M75.034 150.345h17.655v75.034H75.034z"></path>
						<path d="M70.621 220.966h26.483v8.828H70.621zm0-79.449h26.483v8.828H70.621z" fill="#FAB446"></path><path fill="#5064AA" d="M66.207 229.793h35.31v8.828h-35.31z"></path>
						<path fill="#FAB446" d="M207.448 220.966h26.483v8.828h-26.483z"></path><path fill="#5064AA" d="M198.621 229.793h35.31v8.828h-35.31z"></path>
						<path fill="#FAB446" d="M123.586 132.69h52.966v8.828h-52.966z"></path><path fill="#FFB441" d="M145.655 106.207h8.828v26.483h-8.828z"></path>
						<g fill="#F5F5F5"><path d="M141.241 119.448c-7.302 0-13.241-5.94-13.241-13.241 0-7.302 5.94-13.241 13.241-13.241 7.302 0 13.241 5.94 13.241 13.241.001 7.301-5.939 13.241-13.241 13.241zm0-17.655a4.417 4.417 0 00-4.414 4.414 4.417 4.417 0 004.414 4.414 4.416 4.416 0 004.414-4.414 4.417 4.417 0 00-4.414-4.414z"></path>
						<path d="M158.897 119.448c-7.302 0-13.241-5.94-13.241-13.241 0-7.302 5.94-13.241 13.241-13.241 7.302 0 13.241 5.94 13.241 13.241 0 7.301-5.94 13.241-13.241 13.241zm0-17.655a4.417 4.417 0 00-4.414 4.414 4.417 4.417 0 004.414 4.414 4.417 4.417 0 004.414-4.414 4.418 4.418 0 00-4.414-4.414z"></path>
						<path d="M176.552 128.276c-7.302 0-13.241-5.94-13.241-13.241 0-7.302 5.94-13.241 13.241-13.241 7.302 0 13.241 5.94 13.241 13.241 0 7.301-5.94 13.241-13.241 13.241zm0-17.655a4.417 4.417 0 00-4.414 4.414 4.417 4.417 0 004.414 4.414 4.417 4.417 0 004.414-4.414 4.417 4.417 0 00-4.414-4.414zm-52.966 17.655c-7.302 0-13.241-5.94-13.241-13.241 0-7.302 5.94-13.241 13.241-13.241 7.302 0 13.241 5.94 13.241 13.241.001 7.301-5.939 13.241-13.241 13.241zm0-17.655a4.417 4.417 0 00-4.414 4.414 4.417 4.417 0 004.414 4.414 4.417 4.417 0 004.414-4.415 4.416 4.416 0 00-4.414-4.413z"></path></g>
						<path d="M176.552 203.31v4.414a4.419 4.419 0 01-4.414 4.414 4.419 4.419 0 01-4.414-4.414v-4.414h8.828zm8.827-8.827h-26.483v13.241c0 7.302 5.94 13.241 13.241 13.241 7.302 0 13.241-5.94 13.241-13.241v-13.241h.001z" fill="#FAB446"></path><path d="M172.138 176.828A8.829 8.829 0 01163.31 168v-8.828a8.829 8.829 0 0117.656 0V168a8.829 8.829 0 01-8.828 8.828z" fill="#FFA0D2"></path><circle fill="#5064AA" cx="150.069" cy="185.655" r="13.241"></circle><path fill="#FAB446" d="M145.655 88.552h8.828v26.483h-8.828z"></path><path d="M123.586 132.69l-8.828-8.828 5.171-5.171a42.625 42.625 0 0160.28 0l5.171 5.171-8.828 8.828h-52.966z" fill="#C8414B"></path><g transform="translate(128 119)" fill="#FFD250"><circle cx="22.069" cy="4.862" r="4.414"></circle><circle cx="4.414" cy="4.862" r="4.414"></circle><circle cx="39.724" cy="4.862" r="4.414"></circle></g>
						<path d="M70.621 168h44.138v8.828H70.621zm0 35.31l26.482-8.827v-8.828l-26.482 8.828z" fill="#C8414B"></path></g></svg> */}
					ESPAÑOL
				</div>
			</div>
		</div>
    )

}