import config from "../config/config";
import {store} from "../index";

function text(key) {
    const {uiReducer} = store.getState();
    return config.msg[uiReducer.language][key];
}

export {
    text
};
