import React from "react";
import Menu from "./Menu/Menu";
import Hamburger from 'hamburger-react'
import "./Hamburguer.scss";

export default function Hamburguer() {

	const [open, setOpen] = React.useState(false);

	function handleButton() {
		setOpen(!open);
	}

    return (
		<React.Fragment>

			<div className='hamburguer'>
				<Hamburger toggled={open} toggle={handleButton} />
			</div>

			{/* <div className='hamburguer' onClick={handleButton}>
				<div className={!open ? 'open visible' : 'open'}>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className={open ? 'close visible' : 'close'}>
					<div></div>
					<div></div>
				</div>
			</div> */}
			{open && (
				<Menu />
			)}
		</React.Fragment>
    )

}