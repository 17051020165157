import {combineReducers} from 'redux';
import uiReducer from './ui-reducer';
import videoReducer from './video-reducer';
import loginReducer from './login-reducer';

const rootReducer = combineReducers({
    uiReducer,
    videoReducer,
    loginReducer
});

export default rootReducer;