import React from "react";
import {text} from "../../../../services/LanguageService";
import "./Description.scss";

export default function Description() {

    return (
		<div className="description">
			{text("description")}
		</div>
    )

}