import React from "react";
import "./Spinner.scss";

export default function Spinner() {

    return (
		<div className="spinner">
			<div className="spinner-border" role="status">
				<span className="sr-only"></span>
			</div>
		</div>
    )

}