import React from "react";
import "./Logo.scss";

export default function Logo() {

	function handleReload() {
		window.location.replace(window.location.origin);
	}

    return (
		<div className='logo' onClick={handleReload}>
			<img src="syntube_logo.svg" alt="logo" />
			<div className="beta-tag">BETA</div>
		</div>
    )

}