import React from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import {useSelector,useDispatch} from 'react-redux';
import {text} from "../../../services/LanguageService";
import { loginUser, logoutUser, setLoginDialog } from "../../../redux/actions/login-actions";
import LoginDialog from "./Login-Dialog/Login-Dialog";
import "./Login-Button.scss";

export default function LoginButton() {

	const dispatch = useDispatch();
    const {logged, profile} = useSelector(state => state.loginReducer);

	React.useEffect(() => {
		const localLoginData = localStorage.getItem("login");
		if(localLoginData) {
			const {tokenObj, profileObj} = JSON.parse(localLoginData);
			dispatch(loginUser(tokenObj, profileObj))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleLogout() {
		localStorage.removeItem("login");
		dispatch(logoutUser());
	}

	function openLoginDialog() {
		dispatch(setLoginDialog("BUTTON"));
	}

    return (
		<React.Fragment>
			{logged ? (
				<div className="google-logout">
					<div>{text("welcome")}, {profile.givenName}</div>
					<div className="logout-button" onClick={handleLogout}><LogoutIcon /></div>
				</div>
			) : (
				<div className='login-button' onClick={openLoginDialog}>
					<img src="/google.svg" alt="google" /> Sign in
				</div>
			)}
			<LoginDialog />
		</React.Fragment>
    )

}