import React from "react";
import {useSelector} from 'react-redux';
import {text} from '../../../../../../services/LanguageService';
import "./Subtitles.scss";

export default function Subtitles() {
    
    const resultsLanguage = useSelector(state => state.uiReducer.results_language);
    const {subtitles} = useSelector(state => state.videoReducer.metadata[getResultLanguage(resultsLanguage)]);

    function getResultLanguage(resultsLanguage) {
		return resultsLanguage === "GB" ? "en" : resultsLanguage.toLowerCase();
	}

    function download() {
        download("subtitles.txt", subtitles)

        function download(filename, text) {
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    }

    return (
		<div className="subtitles">
            <div className="button-container">
                <button onClick={download}>{text("download_subtitles")}</button>
            </div>
            <div className="text">
                {subtitles}
            </div>
		</div>
    )

}