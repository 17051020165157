import React from "react";
import "./SwitchButton.scss";

export default function SwitchButton(props) {

	const {active,onClick} = props;
	
    return (
		<div className="switch-button" onClick={() => onClick()}>
			{active ? (
				<React.Fragment>
					<div>OFF</div>
					<div className="active">ON</div>
				</React.Fragment>
			) : (
				<React.Fragment>
					<div className="active">OFF</div>
					<div>ON</div>
				</React.Fragment>
			)}
		</div>
    )

}